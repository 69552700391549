import React from "react";
import { DARKER_TEXT, GREEN } from "resources/constants/colors";
import { IMAGES_URL } from "resources/constants/urls";
import styled from "styled-components";
import NoProfileSVG from 'resources/img/no-pic-therapist.png';

const Container = styled.div`
    margin: 0;
    border: 1px solid ${GREEN};
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    .image-container {
        width: 48px;
        height: 48px;
        overflow: hidden;
        border-radius: 8px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .information {
        flex: 1;
        min-height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .texts {
            color: ${DARKER_TEXT};
            h4 {
                font-size: 18px;
                user-select: none;
                margin-bottom: 5px;
                margin: 0;
            }
        }
    }
`;

const PatientCard = ({ person }) => {
    return (
        <Container>
            <div className="image-container">
                <img
                    src={person.profileImg ? `${IMAGES_URL}${person.profileImg}` : NoProfileSVG}
                    alt={`perfil de ${person.name} ${person.lastName}`}
                />
            </div>
            <div className="information">
                <div className="texts">
                    <h4>{`${person.name} ${person.lastName}`}</h4>
                </div>
            </div>
        </Container>
    );
};

export default PatientCard;
