import React from 'react';
import styled from 'styled-components';
import PatientCard from './PatientCard';

const ListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 10px;
    padding: 0;
    margin: 0;
    flex: 1;
    height: 100%;
    overflow: scroll;
    li {
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
`;

const PatientList = ({ list }) => {
    return (
        <ListContainer>
            {list.map((person) => (
                <li key={person.id}>
                    <PatientCard person={person} />
                </li>
            ))}
        </ListContainer>
    );
};

export default PatientList;
