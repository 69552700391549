import React from 'react';
import styled from 'styled-components';
import { Container, Intructions } from '../styles';
import useUser from 'state/user';
import { useMemo } from 'react';
import PatientList from './PatientList';

const PacientsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: 70px;
    max-height: 180px;
`;

const Notice = styled.span`
    margin: 0;
    text-align: center;
`;

const PatientListSection = () => {
    const [user] = useUser();

    const clientsList = useMemo(()=>Array.isArray(user.current.extraData?.clients) ? user.current.extraData.clients : [], [user]);

    return (
        <Container>
            <Intructions>Pacientes</Intructions>
            <PacientsContainer>
                {clientsList.length === 0 ? <Notice>Cuando tengas pacientes asignados, aparecerán aquí.</Notice> : <PatientList list={clientsList}/>}
            </PacientsContainer>
        </Container>
    );
};

export default PatientListSection;
